<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-input-group class="mr-1">
                <b-form-input
                  placeholder="ค้นหาชื่อ นามสกุล เลขบัตรประชาชน"
                  v-model="searchQuery"
                  trim
                />
                <b-input-group-append>
                  <b-button variant="outline-primary" @click="onSearchData()">
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <b-button variant="primary" :to="{ name: 'add-employee' }">
                <span class="text-nowrap">{{ $t("add_employee") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="allEmployee"
        responsive
        :fields="token.type === 'company' ? tableColumns : tableColumnsAdmin"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="constructUrlImage('')"
                :variant="`light-${mappingStatusColor(
                  data.item.employee.role
                )}`"
                :to="{
                  name: 'view-employee',
                  params: { id: data.item.employee._id },
                }"
              />
            </template>
            <b-link
              :to="{
                name: 'view-employee',
                params: { id: data.item.employee._id },
              }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.employee.firstName }}
              {{ data.item.employee.lastName }}
            </b-link>
            <small class="text-muted"
              >@{{ data.item.employee.nationalID }}</small
            >
          </b-media>
        </template>

        <!-- Column: NATIONAL ID -->
        <template #cell(nationalID)="data">
          {{ data.item.employee.nationalID }}
        </template>

        <!-- Column: Role -->
        <!-- <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.department)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.department)}`"
            />
            <span class="align-text-top text-capitalize">{{
              data.item.department
            }}</span>
          </div>
        </template> -->

        <!-- Column: Status -->
        <template #cell(status)="data">
          <span
            style="margin-left: 10px"
            class="bullet bullet-sm"
            :class="`bullet-${mappingStatusColor(data.item.employee.status)}`"
          />
          <!-- <b-badge
          pill
          :variant="`light-${resolveUserStatusVariant('active')}`"
          class="text-capitalize"
        >
          {{ data.item.status || "active" }}
        </b-badge> -->
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-link
            :to="{
              name: 'view-employee',
              params: { id: data.item.employee._id },
            }"
          >
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">{{ $t("detail") }}</span>
          </b-link>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
              {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
              {{ $t("entries") }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BInputGroupAppend,
  BInputGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { constructUrlImage } from "@/libs/helper";
import { avatarText } from "@core/utils/filter";
import useUsersList from "./useUsersList";
import { mappingStatusColor } from "@/libs/helper";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroupAppend,
    vSelect,
    BInputGroup,
  },
  setup() {
    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      onSearchData,
      allEmployee,
      tableColumnsAdmin,

      token,
    } = useUsersList();

    return {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,
      mappingStatusColor,
      onSearchData,
      allEmployee,
      constructUrlImage,

      tableColumnsAdmin,
      token,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
