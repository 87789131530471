import { ref, watch, computed, onMounted } from "@vue/composition-api";
import { employeeService } from "@/services/employee";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import i18n from "@/libs/i18n";
import router from "@/router";
import { decodeToken } from "@/@core/utils/decodeJwt";
export default function useUsersList() {
  // Use toast
  const toast = useToast();
  const refUserListTable = ref(null);
  const token = decodeToken();
  // Table Handlers
  const tableColumns = [
    { key: "user", label: i18n.t("user") },
    { key: "department.name", label: i18n.t("department") },
    // { key: "role" },
    { key: "status", label: i18n.t("status") },
    { key: "actions", label: i18n.t("actions") },
  ];

  const tableColumnsAdmin = [
    { key: "user", label: i18n.t("user") },
    { key: "department.name", label: i18n.t("department") },
    { key: "company.name", label: i18n.t("company") },
    { key: "status", label: i18n.t("status") },
    { key: "actions", label: i18n.t("actions") },
  ];

  const tableColumnsHome = [
    { key: "user", label: i18n.t("user") },
    { key: "department.name", label: i18n.t("department") },
    // { key: "role" },
    { key: "status", label: i18n.t("status") },
  ];

  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const allEmployee = ref([]);
  // const roleFilter = ref(null);
  // const planFilter = ref(null);
  // const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  onMounted(() => {
    fetchUsers();
  });

  watch([currentPage, perPage], () => {
    fetchUsers();
  });

  const onSearchData = () => {
    fetchUsers();
  };

  const fetchUsers = () => {
    const filter = {
      page: currentPage.value,
      size: perPage.value,
      ...(searchQuery.value !== "" ? { searchQuery: searchQuery.value } : {}),
    };

    if (router.currentRoute.query.company) {
      filter.company = router.currentRoute.query.company;
    }
    const queryParams = new URLSearchParams(filter).toString();

    employeeService
      .fetchData(queryParams)
      .then((response) => {
        const { data, pagination } = response;

        if (data) {
          allEmployee.value = data;
          totalUsers.value = pagination.totalItems;
        }
      })
      .catch((error) => {
        console.log(error);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching users list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserStatusVariant = (status) => {
    if (status === "pending") return "warning";
    if (status === "active") return "success";
    if (status === "inactive") return "secondary";
    return "primary";
  };

  return {
    fetchUsers,
    tableColumns,
    tableColumnsHome,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    refetchData,

    onSearchData,
    allEmployee,
    tableColumnsAdmin,

    token,
    // Extra Filters
    // roleFilter,
    // planFilter,
    // statusFilter,
  };
}
